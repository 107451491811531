import { atom } from 'recoil';
import { CallToAction, Variant } from '~/common/models';

export interface ModelColourPicker {
  contentId?: string;
  variants?: Variant[];
  modelName?: string;
  colourDisclaimer?: string;
  bookTestDriveCta?: CallToAction;
  downloadBrochureCta?: CallToAction;
  buildYourOwnCta?: CallToAction;
  isVisible?: boolean;
}

export const ModelColorPickerState = atom<ModelColourPicker>({
  key: 'ModelColorPickerStateKey',
  default: {},
});
